import TokenHelper from "../helpers/TokenHelper";
import RequestHelper from "../helpers/RequestHelper";
import DateTimeHelper from "../core/DateTimeHelper";
import { ExpiredRefreshTokenError } from "../core/CustomErrors";

export default class TokenService {
  #_requestHelper;
  #_tokenHelper;
  #_dateTimeHelper;

  constructor() {
    this._requestHelper = new RequestHelper();
    this._tokenHelper = new TokenHelper();
    this._dateTimeHelper = new DateTimeHelper();
  }

  async initialiseAuthTokens(userEmail) {
    console.log("TokenService.initialiseAuthTokens for " + userEmail);

    const route = "/boom/login";
    let loginToken = this.createLoginToken(userEmail);

    return await this._requestHelper
      .getRequestResponse(route, loginToken, "POST")
      .then((response) => {
        console.log("TokenService " + route + " response: " + response.status);

        if (!response.ok) {
          throw new Error("Failed to initialise authorisation tokens");
        }

        return response.json().then((data) => {
          this._tokenHelper.setAuthTokens(data[0]);
        });
      })
      .catch((err) => {
        throw new Error("Failed to initialise authorisation tokens");
      });
  }

  async checkAccessToken() {
    const route = "/boom/refresh";

    if (!this._tokenHelper.hasAccessTokenExpired()) {
      console.log("TokenService: Access Token valid");
      return;
    }

    console.log("TokenService: Access Token expired");
    console.log(
      "TokenService: Now = " +
        this._dateTimeHelper.nowAsDateTime().toUTCString() +
        " Expiration = " +
        this._tokenHelper.getAccessTokenExpiration().toUTCString()
    );

    if (this._tokenHelper.hasRefreshTokenExpired) {
      console.log("TokenService: Refresh token expired");
      throw new ExpiredRefreshTokenError();
    }

    return await this._requestHelper
      .getRequestResponse(route, this._tokenHelper.getRefreshToken(), "POST")
      .then((response) => {
        console.log("TokenService " + route + " response: " + response.status);

        if (!response.ok) {
          throw new Error("Failed to refresh authorisation tokens");
        }

        return response.json().then((data) => {
          this._tokenHelper.setAuthTokens(data[0]);
          console.log(
            "TokenService: Access Token refreshed. Expiration = " +
              this._tokenHelper.getAccessTokenExpiration().toUTCString()
          );
        });
      })
      .catch((err) => {
        throw new Error("Failed to refresh authorisation tokens");
      });
  }

  createLoginToken(userEmail) {
    const jwt = require("njwt");
    const secret = process.env.REACT_APP_SECRET;
    const iss = process.env.REACT_APP_JWT_ISS;
    const aud = process.env.REACT_APP_JWT_AUD;

    const payload = {
      nameid: userEmail,
      iss: iss,
      aud: aud,
    };
    const token = jwt.create(payload, secret);
    token.setNotBefore(new Date());
    token.setExpiration(new Date().getTime() + 60 * 1000);
    return token.compact();
  }
}
